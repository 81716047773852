import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { ConfigurationControllerService, ConfigurationObject } from 'app/api';
import { AlertService } from 'app/core/alert/alert.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConfigurationsService {
    private _configurations: WritableSignal<ConfigurationObject[]> = signal([]);

    get configurations() {
        return this._configurations.asReadonly();
    }

    private _selectedConfiguration: WritableSignal<ConfigurationObject> = signal(null);

    get selectedConfiguration(): Signal<ConfigurationObject> {
        return this._selectedConfiguration.asReadonly();
    }
    set selectedConfiguration(selectedConfiguration: ConfigurationObject) {
        this._selectedConfiguration.set(selectedConfiguration);
    }

    private _selectedConfigurationItem: WritableSignal<any> = signal(null);

    get selectedConfigurationItem(): Signal<any> {
        return this._selectedConfigurationItem.asReadonly();
    }

    set selectedConfigurationItem(selectedConfigurationItem: any) {
        this._selectedConfigurationItem.set(selectedConfigurationItem);
    }
    constructor(
        private apiService: ConfigurationControllerService,
        private alertService: AlertService
    ) {}

    getConfigurationByType(type: string): ConfigurationObject | null {
        return this._configurations().find(config => config.configType === type);
    }

    updateConfiguration(configuration: ConfigurationObject) {
        lastValueFrom(
            this.apiService.updateConfigurationByType(configuration.configType!, configuration)
        ).then(config => {
            this.alertService.successAlert('configurations', 'updateSuccessMessage', 'updated');
            this.getAllConfigurations();
        });
    }

    deleteConfiguration(type: string) {
        lastValueFrom(this.apiService.deleteConfigurationByType(type)).then(config => {
            this.alertService.successAlert('configurations', 'deleteSuccessMessage', 'deleted');
            this.getAllConfigurations();
        });
    }

    getAllConfigurations() {
        lastValueFrom(this.apiService.getConfigurations()).then(configurations => {
            if (configurations.length === 0) {
                this.createAuthGroups();
            } else if (
                configurations.length &&
                !configurations.find(c => c.configType === 'authGroups')
            ) {
                this.createAuthGroups();
            } else {
                this._configurations.set([...configurations]);
            }
        });
    }

    createConfiguration(configuration: ConfigurationObject) {
        lastValueFrom(this.apiService.addConfigurationType(configuration)).then(config => {
            this.alertService.successAlert('configurations', 'createSuccessMessage', 'created');
            this.getAllConfigurations();
        });
    }

    selectItemFromConfiguration(configuration: ConfigurationObject) {
        this.selectedConfigurationItem = configuration;
    }

    private createAuthGroups(): void {
        const configuration: ConfigurationObject = {
            configType: 'authGroups',
            value: {
                authGroups: [],
            },
        };

        this.createConfiguration(configuration);
    }
}
