import * as i0 from '@angular/core';
import { Injectable, EventEmitter, input, computed, viewChild, Component, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { NgClass } from '@angular/common';
import { TreeViewComponent } from '@em4cloud/tree-view';
const _c0 = ["processTree"];
const _c1 = (a0, a1, a2) => ({
  "red": a0,
  "green": a1,
  "orange": a2
});
function ProcessTreeComponent_ng_template_2_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 6);
  }
  if (rf & 2) {
    const node_r5 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(1, _c1, node_r5.status === "I" || node_r5.status === "INACTIVE", node_r5.status === "A" || node_r5.status === "ACTIVE", node_r5.status === "D" || node_r5.status === "DEVELOPMENT"));
  }
}
function ProcessTreeComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ProcessTreeComponent_ng_template_2_Conditional_0_Template, 1, 5, "span", 4);
    i0.ɵɵelement(1, "span", 5);
  }
  if (rf & 2) {
    const node_r5 = ctx.$implicit;
    i0.ɵɵconditional(0, node_r5.status ? 0 : -1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("innerHTML", node_r5.title, i0.ɵɵsanitizeHtml);
  }
}
function ProcessTreeComponent_ng_template_4_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 6);
  }
  if (rf & 2) {
    const node_r8 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(1, _c1, node_r8.status === "I" || node_r8.status === "INACTIVE", node_r8.status === "A" || node_r8.status === "ACTIVE", node_r8.status === "D" || node_r8.status === "DEVELOPMENT"));
  }
}
function ProcessTreeComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ProcessTreeComponent_ng_template_4_Conditional_0_Template, 1, 5, "span", 4);
    i0.ɵɵelement(1, "span", 5);
  }
  if (rf & 2) {
    const node_r8 = ctx.$implicit;
    i0.ɵɵconditional(0, node_r8.status ? 0 : -1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("innerHTML", node_r8.title, i0.ɵɵsanitizeHtml);
  }
}
var TypeOfProcess;
(function (TypeOfProcess) {
  TypeOfProcess["Process"] = "Process";
  TypeOfProcess["Task"] = "Task";
  TypeOfProcess["Scenario"] = "Scenario";
})(TypeOfProcess || (TypeOfProcess = {}));
var TypeOfProcessTreeNode;
(function (TypeOfProcessTreeNode) {
  TypeOfProcessTreeNode["Process"] = "Process";
  TypeOfProcessTreeNode["Scenario"] = "Scenario";
  TypeOfProcessTreeNode["Folder"] = "Folder";
  TypeOfProcessTreeNode["SubProcess"] = "SubProcess";
})(TypeOfProcessTreeNode || (TypeOfProcessTreeNode = {}));
var FolderType;
(function (FolderType) {
  FolderType["Area"] = "Area";
  FolderType["Process"] = "Process";
})(FolderType || (FolderType = {}));
const UNASSIGNED_AREA_NAME = 'UNASSIGNED';
class ProcessTreeConstructionService {
  constructor() {
    this.SEPARATOR = '&#x29BF;';
    this.processSortFn = (p1, p2) => {
      let aId = p1.meta?.internalId?.split('-')[0].split('.').slice(-1)[0];
      let bId = p2.meta?.internalId?.split('-')[0].split('.').slice(-1)[0];
      let aVersion = p1.meta?.internalId?.split('-')[1];
      let bVersion = p2.meta?.internalId?.split('-')[1];
      if (aId > bId) return 1;else if (aId === bId) return aVersion > bVersion ? 1 : -1;else return -1;
    };
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Process tree creation methods start
  // -----------------------------------------------------------------------------------------------------
  checkIfTreeDataCorrect(processData) {
    let isCorrect = true;
    for (let index = 0; index < processData.length; index++) {
      const process = processData[index];
      if (!process.type) {
        isCorrect = false;
        break;
      }
    }
    return isCorrect;
  }
  findTopProcess(processes, process) {
    if (!process.parentProcess) {
      return process;
    }
    const parentProcess = processes.find(p => p.id === process.parentProcess);
    if (!parentProcess) {
      console.error(`Parent process ${process.parentProcess} not found`);
      return null;
    }
    try {
      return this.findTopProcess(processes, parentProcess);
    } catch (error) {
      console.error('Error finding top process', error);
      return null;
    }
  }
  buildCorrectTreeData(processData) {
    // ParentProcess property is needed to be set
    // internalId in subProcesses are not correct, - * should be -1
    processData = processData.map(process => {
      const internalId = process.internalId;
      const version = process.version;
      // Find parent
      const parentInternalId = internalId.split('-')[0].split('.').slice(0, -1).join('.');
      const parentProcess = processData.find(p => p.internalId === parentInternalId + '-' + version && p.area === process.area && p.version === process.version);
      if (parentProcess) {
        process.parentProcess = parentProcess.id;
        process.type = 'subprocess';
      } else {
        process.type = 'process';
      }
      return process;
    });
    processData = processData.map(process => {
      if (process.parentProcess) {
        process.topProcess = this.findTopProcess(processData, process)?.id;
      }
      return process;
    });
    return processData;
  }
  createProcessTree(processData, testScenarioData, mdoAreas) {
    if (!this.checkIfTreeDataCorrect(processData)) {
      processData = this.buildCorrectTreeData(processData);
      this.corruptTreeDataFixed.emit(processData);
    }
    this.processData = processData;
    this.testScenarioData = testScenarioData;
    this.mdoAreas = cloneDeep(mdoAreas);
    // STEP 1 - IDENTIFYING AREAS AND LEVELS FOR EACH PROCESS
    let treeLevels = this.createAreaTreeLevels();
    // STEP 2 - CREATING RELATIONSHIP BETWEEN FOLDERS AND PROCESSES
    // all area folder names
    if (!this.mdoAreas.some(a => a.id === UNASSIGNED_AREA_NAME)) {
      this.mdoAreas.push({
        children: [],
        id: UNASSIGNED_AREA_NAME,
        name: UNASSIGNED_AREA_NAME,
        internalId: UNASSIGNED_AREA_NAME
      });
    }
    let finalTree;
    // creating the final tree
    finalTree = this.mdoAreas.map(this.createAreaFolder.bind(this, [treeLevels, testScenarioData])).filter(value => value);
    this.processTree = finalTree;
    return finalTree;
  }
  checkIfAreaExists(areaName, areas) {
    if (areaName) {
      for (let index = 0; index < areas.length; index++) {
        const area = areas[index];
        if (area.id === areaName) {
          return true;
        } else if (area.children?.length) {
          const result = this.checkIfAreaExists(areaName, area.children);
          if (result) {
            return true;
          }
        }
      }
    }
    return false;
  }
  createAreaTreeLevels() {
    let treeLevels = new Map();
    // separating each process based on area, then heirarchical level
    this.processData.forEach(process => {
      // using 'unassigned' folder for processes that has no 'area' assigned
      const areaName = this.checkIfAreaExists(process.area, this.mdoAreas) ? process.area : UNASSIGNED_AREA_NAME;
      // adding a new 'area' to the map if it isn't initialized
      if (!treeLevels.has(areaName)) treeLevels.set(areaName, new Map());
      const areaMap = treeLevels.get(areaName); // getting the area map
      // level '0' is for nodes with incorrect heirarchy stucture
      // it will be appended to level 1 at the very end
      let level = 0;
      try {
        let heirarchyParts = [];
        // getting heirarchy level structure
        const internalId = process.internalId;
        if (internalId) {
          heirarchyParts = process.internalId.split('-')[0].split('.').slice(1);
        }
        if (!heirarchyParts || heirarchyParts.length === 0) throw new Error('Heirarchy structure not found');
        level = heirarchyParts.length;
      } catch (e) {}
      if (!areaMap.has(level)) areaMap.set(level, []); // initializing the level if not done already
      // adding the process to the level
      let processNode = this.createProcessNode(process);
      areaMap.get(level).push(processNode);
    });
    return treeLevels;
  }
  createProcessNode(process) {
    let processNode = {
      id: process.id,
      title: 'V - ' + process.version,
      type: TypeOfProcessTreeNode.Process,
      meta: {
        wbs: process.wbs,
        status: process.status,
        area: process.area,
        titel: process.titel,
        version: process.version,
        internalId: process.internalId,
        linkedToSubprocessId: process.linkedToSubprocessId,
        parentProcess: process.parentProcess
      }
    };
    return processNode;
  }
  createProcessFolderNode(process) {
    return {
      id: `${process.id}_folder`,
      type: TypeOfProcessTreeNode.Folder,
      folderType: FolderType.Process,
      title: `${process.meta?.wbs}&nbsp; ${this.SEPARATOR} &nbsp;${process.meta?.titel?.replaceAll('(copy)', '').trim() ?? '-'}`,
      children: [process],
      meta: {
        internalId: process.meta.internalId,
        area: process.meta.area,
        childrenCount: process.children?.length ?? 0,
        versionCount: 0,
        parentProcess: process.meta.parentProcess
      }
    };
  }
  createProcessFolderNode2(process) {
    return {
      id: process.id,
      title: `${process.meta?.wbs}&nbsp; ${this.SEPARATOR} &nbsp;${process.meta?.titel?.replaceAll('(copy)', '').trim() ?? '-'}`,
      type: TypeOfProcessTreeNode.SubProcess,
      folderType: FolderType.Process,
      children: process.children?.length ? process.children : [],
      meta: {
        status: process.meta.status,
        internalId: process.meta.internalId,
        area: process.meta.area,
        childrenCount: process.children?.length ?? 0,
        versionCount: 0,
        titel: process.meta.version,
        parentProcess: process.meta.parentProcess
      }
    };
  }
  createAreaFolderNode(area) {
    return {
      id: area.internalId,
      type: TypeOfProcessTreeNode.Folder,
      folderType: FolderType.Area,
      title: `${area['title']}`,
      children: [],
      meta: {
        area: area.id,
        childrenCount: 0
      }
    };
  }
  createAreaFolder([treeLevels, scenarios], area) {
    const areaNode = this.createAreaFolderNode(area);
    if (area.children) {
      areaNode.children = area.children.map(this.createAreaFolder.bind(this, [treeLevels, scenarios]));
      areaNode.children = areaNode.children.filter(value => value !== undefined);
    }
    // looping over each level in that area (folder)
    let areaMap = treeLevels.get(area.id);
    if (!areaMap) {
      // no process in the area, but still may have children areas
      if (areaNode.children?.length > 0) return areaNode;
      return;
    }
    // sorting the map based on level 0 onwards
    areaMap = new Map([...areaMap.entries()].sort());
    areaMap.forEach((processes, level) => {
      // sorting the processes based on internal id
      processes = processes.sort(this.processSortFn);
      // adding test scenarios for processes
      if (this.testScenarioData) {
        processes.forEach(process => {
          this.addTestsToProcessTree(process, scenarios);
        });
      }
      processes = this.groupProcessVersions(processes, level);
      areaMap.set(level, processes); // update area map so that it can be used again for finding parents
      if (!areaNode.children) areaNode.children = [];
      if (level === 0) {
        // handle this level at the end so that
        // incorrect wbs structure nodes will be at the end during display
      } else if (level === 1) {
        areaNode.children.push(...processes);
        areaNode.meta.childrenCount = processes.length ?? 0;
      } else {
        // looping over each process to attach it to its parent
        processes.forEach(process => {
          const parentProcesses = areaMap.get(level - 1);
          if (!parentProcesses || parentProcesses.length === 0) {
            if (!areaMap.has(0)) areaMap.set(0, []);
            areaMap.get(0).push(process);
          } else {
            // parent internal id level to compare to
            const processCompareString = process.meta?.internalId.split('-')[0].split('.').slice(0, -1).join('.');
            // finding parent based on parentProcessId recursively
            const findParent = (processes, process) => {
              const parent = processes.find(parentProcess => {
                return process.meta.parentProcess === parentProcess.id;
              });
              if (parent) {
                return parent;
              } else {
                for (let index = 0; index < processes.length; index++) {
                  const p = processes[index];
                  if (p.children?.length) {
                    let result = findParent(p.children, process);
                    if (result) {
                      return result;
                    }
                  }
                }
              }
            };
            // finding parent based on internal id
            const parent = findParent(parentProcesses, process);
            //Definen subParent by the parentProcess id
            const subParent = parent?.children?.find(p => p.id === process.meta.parentProcess);
            // if parent could not be found
            if (!parent) {
              if (!areaMap.get(0)) areaMap.set(0, []);
              areaMap.get(0).push(process);
            } else if (parent && !subParent) {
              if (!parent.children) {
                parent.children = [];
              }
              parent.children.push(process);
              if (parent.meta.childrenCount === undefined) parent.meta.childrenCount = 0;
              parent.meta.childrenCount++;
            }
            // if parent found
            else {
              if (!subParent.children) subParent.children = [];
              subParent.children.push(process);
              if (subParent.meta.childrenCount === undefined) subParent.meta.childrenCount = 0;
              subParent.meta.childrenCount++;
            }
          }
        });
      }
    });
    // adding level 0 elements at the end
    areaNode.children.push(...(areaMap.get(0) || []));
    return areaNode;
  }
  sortProcesses() {}
  groupProcessVersions(processes, level) {
    // helper function to get process version
    const getVersion = process => {
      const internalId = process.meta?.internalId;
      if (!internalId) {
        return 0;
      }
      return parseInt(internalId.split('-')[1]);
    };
    let nodes = [];
    for (let i = processes.length - 1; i >= 0;) {
      let process = processes[i];
      let version = getVersion(process);
      // create a process folder
      let processFolderNode;
      processFolderNode = this.createProcessFolderNode(process);
      if (level < 2) processFolderNode = this.createProcessFolderNode(process);else processFolderNode = this.createProcessFolderNode2(process);
      let j = i - 1;
      let lastVersion = version;
      while (j >= 0) {
        let nextProcess = processes[j];
        let nextVersion = getVersion(nextProcess);
        if (lastVersion <= nextVersion) {
          break;
        }
        processFolderNode.children.unshift(nextProcess);
        lastVersion = nextVersion;
        j--;
      }
      processFolderNode.meta.versionCount = processFolderNode?.children.length ?? 0;
      nodes.push(processFolderNode);
      i = j;
    }
    return nodes.reverse();
  }
  addTestsToProcessTree(process, scenarios) {
    const filterSceenarios = scenarios?.filter(scenario => scenario.bp_id === process.id);
    filterSceenarios?.forEach(scenario => {
      let item = {
        id: scenario.id,
        type: TypeOfProcessTreeNode.Scenario,
        title: scenario.wbs + ' - ' + scenario.description
      };
      if (!process.children) process.children = [];
      process.children.push(item);
    });
  }
  static {
    this.ɵfac = function ProcessTreeConstructionService_Factory(t) {
      return new (t || ProcessTreeConstructionService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ProcessTreeConstructionService,
      factory: ProcessTreeConstructionService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProcessTreeConstructionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class ProcessTreeComponent {
  constructor(_processTreeConstructionService) {
    this._processTreeConstructionService = _processTreeConstructionService;
    this.treeNodeSelectionChange = new EventEmitter();
    this.treeNodeClick = new EventEmitter();
    this.menuItemClick = new EventEmitter();
    this.dropListDrop = new EventEmitter();
    this.corruptTreeDataFixed = new EventEmitter();
    this.canChangeTreeNodeSelection = input();
    this.processData = input([]);
    this.testScenarioData = input(null);
    this.mdoAreas = input(null);
    this.editMode = input(false);
    this.selectedProcess = input(null);
    this.translocoContent = input({});
    this.selectedProcessId = computed(() => this.selectedProcess()?.id);
    this.processTree = viewChild('processTree');
    this.transformFunction = input((node, level) => {
      let flatNode = {
        id: node.id,
        type: node.type,
        folderType: node.folderType,
        expandable: !!node.children,
        level: level,
        title: node.title,
        status: node.meta?.status,
        area: node.meta?.area,
        childrenCount: node.meta?.childrenCount,
        versionCount: node.meta?.versionCount
      };
      if (node.type === TypeOfProcessTreeNode.Scenario) {
        flatNode.title = `${node.title} Scenario`;
        flatNode.checkbox = true;
        flatNode.icon = 'assignment';
      } else if (node.type === TypeOfProcessTreeNode.Folder) {
        flatNode.title = node.title;
        flatNode.icon = node.folderType === FolderType.Area ? 'mat_solid:folder' : 'file_copy';
        flatNode.expandable = false;
      } else {
        flatNode.title = node.title;
        flatNode.checkbox = this.testScenarioData() ? false : true;
      }
      return flatNode;
    });
    this._processTreeConstructionService.corruptTreeDataFixed = this.corruptTreeDataFixed;
    // setting tree configuration
    this.setTreeConfiguration();
    this.treeData = computed(() => this._processTreeConstructionService.createProcessTree(this.processData(), this.testScenarioData(), this.mdoAreas()));
  }
  setTreeConfiguration() {
    this.treeConfig = computed(() => {
      let config = {
        allowNodeDeselection: false,
        canChangeTreeNodeSelection: this.canChangeTreeNodeSelection(),
        contextMenuConfig: {
          enableContextMenu: true,
          contextMenuItems: [{
            id: 'copy',
            title: this.translocoContent()['copy'] ?? 'copy',
            isVisible: node => {
              return node.type === TypeOfProcessTreeNode.Process && this.editMode();
            }
          }, {
            id: 'delete',
            title: this.translocoContent()['delete'] ?? 'delete',
            isVisible: node => {
              return this.editMode() && node.status === 'D' && (node.type === TypeOfProcessTreeNode.Process || node.type === TypeOfProcessTreeNode.SubProcess);
            }
          }, {
            id: 'shareLink',
            title: this.translocoContent()['shareLink'] ?? 'shareLink',
            isVisible: node => {
              return node.type !== TypeOfProcessTreeNode.Folder;
            }
          }, {
            id: 'addChild',
            title: this.translocoContent()['addChild'] ?? 'addChild',
            isVisible: node => {
              return this.editMode() && node.status === 'D' && (node.folderType === FolderType.Process || node.type === TypeOfProcessTreeNode.SubProcess || node.type === TypeOfProcessTreeNode.Process);
            }
          }, {
            id: 'addVersion',
            title: this.translocoContent()['addVersion'] ?? 'addVersion',
            isVisible: node => {
              return this.editMode() && (node.status === 'A' || node.status === 'ACTIVE') && node.type === TypeOfProcessTreeNode.Process;
            }
          }]
        }
      };
      return config;
    });
  }
  treeNodeSelectionChanged(node) {
    this.treeNodeSelectionChange.emit(node);
  }
  treeNodeClicked(node) {
    this.treeNodeClick.emit(node);
  }
  dropListDropped($event) {
    this.dropListDrop.emit($event);
  }
  menuItemClicked($event) {
    this.menuItemClick.emit($event);
  }
  static {
    this.ɵfac = function ProcessTreeComponent_Factory(t) {
      return new (t || ProcessTreeComponent)(i0.ɵɵdirectiveInject(ProcessTreeConstructionService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ProcessTreeComponent,
      selectors: [["lib-process-tree"]],
      viewQuery: function ProcessTreeComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.processTree, _c0, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        canChangeTreeNodeSelection: [i0.ɵɵInputFlags.SignalBased, "canChangeTreeNodeSelection"],
        processData: [i0.ɵɵInputFlags.SignalBased, "processData"],
        testScenarioData: [i0.ɵɵInputFlags.SignalBased, "testScenarioData"],
        mdoAreas: [i0.ɵɵInputFlags.SignalBased, "mdoAreas"],
        editMode: [i0.ɵɵInputFlags.SignalBased, "editMode"],
        selectedProcess: [i0.ɵɵInputFlags.SignalBased, "selectedProcess"],
        translocoContent: [i0.ɵɵInputFlags.SignalBased, "translocoContent"],
        transformFunction: [i0.ɵɵInputFlags.SignalBased, "transformFunction"]
      },
      outputs: {
        treeNodeSelectionChange: "treeNodeSelectionChange",
        treeNodeClick: "treeNodeClick",
        menuItemClick: "menuItemClick",
        dropListDrop: "dropListDrop",
        corruptTreeDataFixed: "corruptTreeDataFixed"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 6,
      vars: 4,
      consts: [[1, "process-tree", 3, "data", "treeConfig", "transformFunction", "selectNodeId", "treeNodeSelectionChange", "treeNodeClick", "menuItemClick", "dropListDrop"], ["processTree", ""], ["leafNode", ""], ["expandableNode", ""], ["class", "status", 3, "ngClass"], [1, "my-auto", 3, "innerHTML"], [1, "status", 3, "ngClass"]],
      template: function ProcessTreeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "custom-tree", 0, 1);
          i0.ɵɵlistener("treeNodeSelectionChange", function ProcessTreeComponent_Template_custom_tree_treeNodeSelectionChange_0_listener($event) {
            return ctx.treeNodeSelectionChanged($event);
          })("treeNodeClick", function ProcessTreeComponent_Template_custom_tree_treeNodeClick_0_listener($event) {
            return ctx.treeNodeClicked($event);
          })("menuItemClick", function ProcessTreeComponent_Template_custom_tree_menuItemClick_0_listener($event) {
            return ctx.menuItemClicked($event);
          })("dropListDrop", function ProcessTreeComponent_Template_custom_tree_dropListDrop_0_listener($event) {
            return ctx.dropListDropped($event);
          });
          i0.ɵɵtemplate(2, ProcessTreeComponent_ng_template_2_Template, 2, 2, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor)(4, ProcessTreeComponent_ng_template_4_Template, 2, 2, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("data", ctx.treeData)("treeConfig", ctx.treeConfig())("transformFunction", ctx.transformFunction())("selectNodeId", ctx.selectedProcessId);
        }
      },
      dependencies: [TreeViewComponent, NgClass],
      styles: [".process-tree[_ngcontent-%COMP%]   .status[_ngcontent-%COMP%]{min-width:.75rem;min-height:.75rem;width:.75rem;height:.75rem;margin-top:.25rem;margin-right:.5rem;aspect-ratio:1/1;border-radius:9999px}.process-tree[_ngcontent-%COMP%]   .orange[_ngcontent-%COMP%]{background-color:#f97316}.process-tree[_ngcontent-%COMP%]   .red[_ngcontent-%COMP%]{background-color:#ef4444}.process-tree[_ngcontent-%COMP%]   .green[_ngcontent-%COMP%]{background-color:#22c55e}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProcessTreeComponent, [{
    type: Component,
    args: [{
      selector: 'lib-process-tree',
      standalone: true,
      imports: [TreeViewComponent, NgClass],
      template: "<custom-tree\n    class=\"process-tree\"\n    #processTree\n    [data]=\"treeData\"\n    [treeConfig]=\"treeConfig()\"\n    [transformFunction]=\"transformFunction()\"\n    [selectNodeId]=\"selectedProcessId\"\n    (treeNodeSelectionChange)=\"treeNodeSelectionChanged($event)\"\n    (treeNodeClick)=\"treeNodeClicked($event)\"\n    (menuItemClick)=\"menuItemClicked($event)\"\n    (dropListDrop)=\"dropListDropped($event)\">\n    <ng-template #leafNode let-node>\n        @if (node.status) {\n        <span\n            class=\"status\"\n            [ngClass]=\"{\n                    'red': node.status === 'I' || node.status === 'INACTIVE',\n                    'green': node.status === 'A' || node.status === 'ACTIVE',\n                    'orange': node.status === 'D' || node.status === 'DEVELOPMENT',\n                }\"></span>\n        }\n        <span class=\"my-auto\" [innerHTML]=\"node.title\"></span>\n    </ng-template>\n\n    <ng-template #expandableNode let-node>\n        @if (node.status) {\n        <span\n            class=\"status\"\n            [ngClass]=\"{\n                    'red': node.status === 'I' || node.status === 'INACTIVE',\n                    'green': node.status === 'A' || node.status === 'ACTIVE',\n                    'orange': node.status === 'D' || node.status === 'DEVELOPMENT',\n                }\"></span>\n        }\n        <span class=\"my-auto\" [innerHTML]=\"node.title\"></span>\n    </ng-template>\n</custom-tree>\n",
      styles: [".process-tree .status{min-width:.75rem;min-height:.75rem;width:.75rem;height:.75rem;margin-top:.25rem;margin-right:.5rem;aspect-ratio:1/1;border-radius:9999px}.process-tree .orange{background-color:#f97316}.process-tree .red{background-color:#ef4444}.process-tree .green{background-color:#22c55e}\n"]
    }]
  }], () => [{
    type: ProcessTreeConstructionService
  }], {
    treeNodeSelectionChange: [{
      type: Output
    }],
    treeNodeClick: [{
      type: Output
    }],
    menuItemClick: [{
      type: Output
    }],
    dropListDrop: [{
      type: Output
    }],
    corruptTreeDataFixed: [{
      type: Output
    }]
  });
})();

/*
 * Public API Surface of process-tree
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FolderType, ProcessTreeComponent, ProcessTreeConstructionService, TypeOfProcess, TypeOfProcessTreeNode, UNASSIGNED_AREA_NAME };
