import { Injectable, Signal, WritableSignal, computed, effect, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { BpmUniversalDataObject, System, UserControllerService, UserDto } from 'app/api';
import { TranslateService } from 'app/core/transloco/translate.service';
import { languages } from 'app/core/transloco/transloco.data';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';
import { ConfigurationsService } from 'app/modules/configurations/configurations.service';
import { objectExplorerItemStatuses, statuses } from 'app/modules/master-data/master-data/mdo.data';
import { AreaService } from 'app/modules/org-sys-area/services/area.service';
import { SysService } from 'app/modules/org-sys-area/services/sys.service';
import { cloneDeep } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class NewMDOService {
    private _users: WritableSignal<Array<UserDto>> = signal([]);
    public get users() {
        return this._users.asReadonly();
    }
    public get usersForSelect() {
        const users = this._users().map(user => {
            return {
                value: user.employeeId,
                label: user.firstName + ' ' + user.lastName,
            };
        });

        return users;
    }

    public get departments() {
        return this.configService.getConfigurationByType('authGroups')?.value['authGroups'];
    }

    private _accounts: WritableSignal<Array<BpmUniversalDataObject>> = signal([]);
    public get accounts() {
        return this._accounts.asReadonly();
    }

    public areas() {
        return this.areaService.state();
    }

    private _systems: WritableSignal<Array<System>> = signal([]);
    public get systems() {
        return this._systems.asReadonly();
    }

    private _translocoContentMdo: Signal<Translation>;
    private _translocoContentLanguages: Signal<Translation>;
    private _languages: Signal<Array<any>>;
    public get languages() {
        return this._languages;
    }
    get statuses() {
        return this._translateService.replaceKeyWithContent(
            cloneDeep(statuses),
            this._translocoContentMdo()
        );
    }

    get objectExplorerStatuses() {
        return this._translateService.replaceKeyWithContent(
            cloneDeep(objectExplorerItemStatuses),
            this._translocoContentMdo()
        );
    }

    private _hasBeenSetUp: WritableSignal<boolean> = signal(false);
    public get hasBeenSetUp() {
        return this._hasBeenSetUp.asReadonly();
    }

    constructor(
        private orgFilterService: OrganisationFilterService,
        private usersService: UserControllerService,
        private areaService: AreaService,
        private sysService: SysService,
        private configService: ConfigurationsService,
        private _translocoService: TranslocoService,
        private _translateService: TranslateService
    ) {
        effect(
            () => {
                if (this.orgFilterService.selectedOrganisation()) {
                    this.initUsers();
                    this.initDepartments();
                    this.initAccounts();
                    this.initSystems();

                    this._hasBeenSetUp.set(true);
                }
            },
            {
                allowSignalWrites: true,
            }
        );

        this._translocoContentMdo = toSignal(
            this._translocoService.selectTranslation('master-data')
        );
        this._translocoContentLanguages = toSignal(
            this._translocoService.selectTranslateObject('languages')
        );
        this._languages = computed(() => {
            return languages.map(language => ({
                ...language,
                label: this._translocoContentLanguages()[language.label],
            }));
        });
    }

    private initUsers(): void {
        this.usersService
            .getUsers(this.orgFilterService.selectedOrganisation())
            .toPromise()
            .then(users => {
                this._users.set(
                    users.map(user => {
                        let fullName = user.employeeId;
                        if (user.firstName && user.lastName) {
                            fullName = `${user.firstName} ${user.lastName}`;
                        }
                        return { ...user, fullName };
                    })
                );
            });
    }

    private initDepartments(): void {
        this.configService.getAllConfigurations();
    }

    private initAccounts(): void {
        // TODO: init accounts
        this._accounts.set([
            {
                id: 'BPM-1',
                name: 'Mitarbeiter',
                users: [],
                index: 0,
            },
            {
                id: 'BPM-2',
                name: 'Prozessverantwortliche',
                users: [],
                index: 1,
            },
            {
                id: 'BPM-3',
                name: 'Bereichsverantwortliche',
                users: [],
                index: 2,
            },
            {
                id: 'EM-1',
                name: 'Administrator',
                users: [],
                index: 3,
            },
            {
                id: 'CO-1',
                name: 'Production Controller',
                users: [],
                index: 4,
            },
            {
                id: 'FI-GL',
                name: 'FI General Ledger Accountant',
                users: [],
                index: 5,
            },
            {
                id: 'GPO-FICO',
                name: 'Global Process Owner FICO',
                users: [],
                index: 6,
            },
            {
                id: 'FI-AR',
                name: 'Accounts Receivable Accountant',
                users: [],
                index: 7,
            },
            {
                id: 'FSCM',
                name: 'Credit Controller',
                users: [],
                index: 8,
            },
            {
                id: 'SD',
                name: 'Cust. Service',
                users: [],
                index: 9,
            },
            {
                children: [
                    {
                        children: [],
                        id: 'EM-PMO',
                        name: 'Project Management Office',
                        internalId: 'AA.11.1',
                    },
                    {
                        children: [],
                        id: 'EM-HR',
                        name: 'Human Resources',
                        internalId: 'AA.11.2',
                    },
                ],
                id: 'EM',
                name: 'Enterprisemind',
                internalId: 'AA.11',
            },
            {
                children: [
                    {
                        children: [],
                        id: 'LM-CS',
                        name: 'Customer Service',
                        internalId: 'AA.12.1',
                    },
                ],
                id: 'LM',
                name: 'Logistic Managemenet',
                internalId: 'AA.12',
            },
        ]);
    }

    private initSystems(): void {
        this._systems.set(this.sysService.state());
    }
}
