import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    WritableSignal,
    effect,
    signal,
} from '@angular/core';
import { SVG, Svg } from '@svgdotjs/svg.js';
import {
    BusinessProcessCategory,
    BusinessProcessTask,
    BusinessProcessTaskDataInterface,
} from 'app/api';
import { Observable, lastValueFrom } from 'rxjs';
import { FlowchartLoadingSpinnerService } from '../../reusable-components/flowchart/flowchart-container/flowchart-loading-spinner.service';
import { DependencyService } from '../svg-flowchart/dependency.service';
import { BpTaskDataType } from './svg-bp-task-data-type.model';
import properties from './svgjs.properties';
import { SvgjsService } from './svgjs.service';

@Component({
    selector: 'lib-svgjs',
    templateUrl: './svgjs.component.html',
    styleUrl: './svgjs.component.scss',
})
export class SvgjsComponent implements AfterViewInit, OnChanges {
    @ViewChild('svgContainer') svgContainer!: ElementRef;

    @Input() isStartPoint: boolean;
    @Input() isEndPoint: boolean;

    @Input() tasks: WritableSignal<BusinessProcessTask[]>;
    @Input() task: BusinessProcessTask;

    private svg: Svg;

    private yMultiplier: number;

    constructor(
        private service: SvgjsService,
        private http: HttpClient,
        private spinnerService: FlowchartLoadingSpinnerService,
        private dependencyService: DependencyService
    ) {
        effect(() => {
            if (this.tasks && this.tasks().length === 1) {
                this.setUpSvgScene();
            }
        });
    }
    ngAfterViewInit(): void {
        if (this.isStartPoint || this.isEndPoint) {
            this.setUpSvgScene();
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.task && !this.tasks) {
            this.tasks = signal([this.task]);
        }
    }

    // #region Set up SVG Scene

    /**
     * Sets up the SVG scene in the container by either creating the start or end point
     * of the flowchart or by creating the tasks and entries for the given task.
     * Then it creates the arrow to the next task and adjusts the view box to fit the content.
     * Finally, it hides the loading spinner.
     *
     * @returns Promise that resolves when the SVG scene is set up.
     */
    async setUpSvgScene(): Promise<void> {
        // Clear or initialize the SVG container
        this.svg ? this.svg.clear() : (this.svg = SVG().addTo(this.svgContainer.nativeElement));

        let maxLength: number;

        // Determine maxLength based on the task type
        if (this.isStartPoint || this.isEndPoint) {
            maxLength = 0;
            await this.createStartOrEndPoint();
            await this.setUpArrowForDependency(null, 'direct');
        } else {
            const { input, output } = this.tasks()[0].data;
            maxLength = Math.max(input.length, output.length);
            await this.createTasksAndEntries(maxLength);
        }

        // Adjust view box to fit content
        this.adjustViewBoxToFitContent();

        // Hide loading spinner
        this.spinnerService.showLoadingSpinner.set(false);
    }

    /**
     * Creates the start or end point of the flowchart by loading the start or end SVG
     * and setting it up with the SVG container.
     *
     * @remarks
     * This function is called when the component is initialized and only when the
     * component is a start or end point.
     */
    private async createStartOrEndPoint(): Promise<void> {
        const svgName = this.isStartPoint ? BpTaskDataType.start : BpTaskDataType.end;

        this.fetchAndLoadMainSVG(svgName, BpTaskDataType.start);
    }

    /**
     * Create SVG for a task with entries (inputs or outputs).
     *
     * @param maxLength The maximum length of input or output entries
     *
     * 1. Create Process / Decision
     * 2. Create Inputs if any
     * 3. Create Outputs if any
     * 4-6. Handle Indirect Dependencies
     */
    private async createTasksAndEntries(maxLength: number): Promise<void> {
        // Set yMultiplier, default to 1 if non-finite
        this.yMultiplier = Number.isFinite(100 / maxLength) ? 100 / maxLength : 1;

        // 1. Create Process / Decision
        const task = this.tasks()[0];
        let category =
            task.category === BusinessProcessCategory.IntegrationTask ? 'process' : task.category;

        let svgName: string;

        if (category === BusinessProcessCategory.Process || category === 'process') {
            svgName = 'process';

            const actionCategory = task.data.action.category;
            svgName += ['CONTROL', 'M_PROCESS', 'PROCESS'].includes(actionCategory)
                ? actionCategory
                : 'PROCESS';

            await this.fetchAndLoadMainSVG(svgName, BpTaskDataType.process);
            await this.loadActorSvg();
        } else if (category === BusinessProcessCategory.Decission) {
            svgName = 'decision';
            await this.fetchAndLoadMainSVG(svgName, BpTaskDataType.decision);
        }

        // 2. Create Inputs if any
        if (task.data.input?.length) {
            await this.setUpArrowForEntry(
                'mainEnd',
                properties.main[category].x,
                properties.main[category].y
            );
            await this.setUpEntriesForProcesses(task.data.input, 'input');
        } else {
            await this.setUpBlankEntryForProcess('input');
        }

        // 3. Create Outputs if any
        if (task.data.output?.length) {
            await this.setUpArrowForEntry(
                'mainStart',
                properties.main[category].x,
                properties.main[category].y
            );

            if (category === BusinessProcessCategory.Process) {
                await this.setUpEntriesForProcesses(task.data.output, 'output');
            } else {
                await this.setUpEntriesForDecision(task.data.output);
            }
        } else {
            await this.setUpBlankEntryForProcess('output');
        }

        await this.handleTaskDependencies(task);
    }

    /**
     * Loads the actor SVG according to the category of the task.
     * If the SVG is not already loaded, it fetches the SVG from the assets folder.
     * If the category is an integration task, it sets the actor SVG to automatic.
     * If the category is a SAP task, it sets the actor SVG to sap and replaces placeholders in the SVG with the task info.
     * If the category is an Ai task, it sets the actor SVG to ai.
     * If the category is a process task, it sets the actor SVG to human.
     * It then adds the SVG content to the SVG container and positions the actor SVG according to the properties of the category.
     * If the process is a start or end point, the actor SVG is 5mm high, otherwise it is 150mm high.
     */
    private async loadActorSvg(): Promise<void> {
        let svgName: 'human' | 'ai' | 'automatic' | 'sap' = 'human';

        if (this.tasks()[0].category === BusinessProcessCategory.IntegrationTask) {
            svgName = 'automatic';
        } else if (this.tasks()[0].data.action.category === 'SAP_TASK') {
            svgName = 'sap';
        }
        // TODO uncomment when AiTask is available
        // else if (this.tasks()[0].category === BusinessProcessCategory.AiTask) {
        //     svgName = 'ai'
        // }
        if (svgName === 'human') {
            return;
        }

        if (!this.service.main.get(svgName)) {
            const svg = await lastValueFrom(this.fetchSvg(svgName));
            this.service.main.set(svgName, { svg, type: BpTaskDataType.actor });
        }

        let svgContent = this.service.main.get(svgName).svg;
        if (svgName === 'sap') {
            if (!this.tasks()[0].data.info) {
                this.tasks()[0].data.info = {
                    name: '',
                };
            }
            svgContent = this.replacePlaceholdersWithContents(
                svgContent,
                properties.main.sapIcon.content,
                this.tasks()[0]
            );
        }
        const entries = svgName === 'sap' ? properties.main.sapIcon : properties.main.actorIcon;

        let content = this.svg.toString();
        content += svgContent;

        this.svg.svg(content);

        const x = entries.x;
        const y = entries.y;
        const width = entries.width;
        let height = entries.height;

        const length = this.svg.children().length;
        this.svg.children()[length - 1].width(`${width}%`);
        height = this.isStartPoint || this.isEndPoint ? 5 : 150;
        this.svg.children()[length - 1].height(`${height}mm`);
        this.svg.children()[length - 1].move(`${x}%`, `${y}mm`);
    }

    /**
     * Adjusts the viewBox of the SVG element to fit the content.
     *
     * This is needed because the SVG elements are initially rendered with a viewBox that is
     * too large, and the content is not centered. This method adjusts the viewBox to fit the
     * content, and then adjusts the position of the arrows to be centered within the viewBox.
     *
     * If the task is a start or end point, the viewBox is not adjusted.
     */
    private adjustViewBoxToFitContent(): void {
        if (!this.isStartPoint && !this.isEndPoint) {
            const bbox = this.svg.bbox(); // Get the bounding box of the SVG content
            this.svg.viewbox(bbox); // Set the viewBox to fit the bounding box
        }

        this.svg.children().forEach(svg => {
            if (
                svg.id() === 'entry' ||
                svg.id() === 'arrow' ||
                svg.id() === 'verticalArrow' ||
                svg.id() === 'arrowVerticalStartEnd' ||
                svg.id() === 'arrowVerticalStart' ||
                svg.id() === 'arrowVerticalEnd'
            ) {
                let oldY = +svg.y().toString().split('%')[0];
                const index = oldY / properties.entries.yMultiplier;

                let newY = index * this.yMultiplier;

                if (svg.id() === 'verticalArrow') {
                    newY -= this.yMultiplier / 2;
                } else if (svg.id() === 'arrowVerticalStartEnd') {
                    newY -= 17;
                }

                svg.y(`${newY}%`);
            }
        });
    }

    /**
     * Fetches the SVG with the given name from the assets folder.
     * The SVG is returned as text.
     * @param svgName The name of the SVG to fetch.
     * @returns An Observable that resolves with the SVG content as text.
     */
    fetchSvg(svgName: string): Observable<string> {
        return this.http.get(`/assets/svgs/${svgName}.svg`, { responseType: 'text' });
    }

    // #endregion

    // #region Task or Decision

    /**
     * Fetches the SVG for the given svgName and loads it into the SvgjsService.
     * If the svgName includes 'process_', it is replaced with 'process'.
     * @param svgName The name of the SVG to fetch and load
     * @param type The type of the SVG (start, end, process, decision, task, integration, ...).
     * The type is used to determine which properties to use for positioning and sizing the SVG.
     * @returns A Promise that resolves when the SVG has been loaded.
     */
    async fetchAndLoadMainSVG(svgName: string, type: BpTaskDataType): Promise<void> {
        if (svgName.includes('process_')) {
            // TODO IntegrationTask
            svgName = 'process';
        }
        if (!this.service.main.get(svgName)) {
            const svg = await lastValueFrom(this.fetchSvg(svgName));
            this.service.main.set(svgName, { svg, type });
        }
        this.modifyMainSVG(svgName, this.service.main.get(svgName).svg);
    }

    /**
     * Replace placeholders in the SVG content with corresponding values from the data object.
     *
     * @param svgContent The SVG content with placeholders to be replaced
     * @param contents An array of objects containing placeholder and value pairs
     * @param data The data object containing the values to replace the placeholders
     * @returns The SVG content with replaced placeholders
     */
    private replacePlaceholdersWithContents(
        svgContent: string,
        contents: { placeholder: string; value: string }[],
        data: any
    ): string {
        contents.forEach(content => {
            const keys = content.value.split('.');

            let value = data;
            keys.forEach(key => {
                value = value[key];
            });

            svgContent = svgContent.replace(content.placeholder, value);
        });

        return svgContent;
    }

    /**
     * Replace placeholders in the SVG content with corresponding values from the data object.
     * If the keys include 'credits[i]', replace placeholders for each credit entry.
     * If the keys include 'debits[i]', replace placeholders for each debit entry.
     * Otherwise, replace placeholders with values extracted from the data object.
     *
     * @param svgContent The SVG content with placeholders to be replaced
     * @param contents An array of objects containing placeholder and value pairs
     * @param data The data object containing the values to replace the placeholders
     * @returns The SVG content with replaced placeholders
     */
    private replacePlaceholdersWithContentsForFiDocument(
        svgContent: string,
        contents: { placeholder: string; value: string }[],
        data: any
    ): string {
        contents.forEach(content => {
            const keys = content.value.split('.');

            if (keys.includes('credits[i]')) {
                data.metadata.credits.forEach((credit, index) => {
                    let placeholder = content.placeholder.replace('i', index.toString());
                    let value = credit[keys[keys.length - 1]];
                    svgContent = svgContent.replace(placeholder, value);
                });
            } else if (keys.includes('debits[i]')) {
                data.metadata.debits.forEach((debit, index) => {
                    let placeholder = content.placeholder.replace('i', index.toString());
                    let value = debit[keys[keys.length - 1]];
                    svgContent = svgContent.replace(placeholder, value);
                });
            } else {
                let value = data;
                keys.forEach(key => {
                    value = value[key];
                });

                svgContent = svgContent.replace(content.placeholder, value);
            }
        });

        return svgContent;
    }

    /**
     * Modify the main SVG according to the given SVG name and content.
     * Replaces placeholders with contents and sets the viewBox, position and size of the main SVG.
     * @param svgName the name of the SVG
     * @param svgContent the content of the SVG
     */
    private modifyMainSVG(svgName: string, svgContent: string) {
        let mainProperties: any;
        let viewBox: any;
        if (this.isStartPoint || this.isEndPoint) {
            viewBox = properties.container.startViewBox;
        } else {
            viewBox = properties.container.viewBox;
        }

        if (this.service.main.get(svgName).type === 'process') {
            mainProperties = properties.main.process;
        } else if (this.service.main.get(svgName).type === 'decision') {
            mainProperties = properties.main.decission;
        } else {
            mainProperties = properties.main.start;
        }

        if (
            this.service.main.get(svgName).type === 'process' ||
            this.service.main.get(svgName).type === 'decision'
        ) {
            svgContent = this.replacePlaceholdersWithContents(
                svgContent,
                mainProperties.content,
                this.tasks()[0]
            );
        }

        const main = this.svg.svg(svgContent);
        main.viewbox(viewBox.x, viewBox.y, viewBox.width, viewBox.height);

        this.setPositionAndSizeToSvg(
            mainProperties.x,
            mainProperties.y,
            mainProperties.width,
            mainProperties.height
        );
    }
    // #endregion

    // #region Entries

    /**
     * This function takes an array of BusinessProcessTaskDataInterface and a type 'input' or 'output'
     * and sets up the entries for processes. It will fetch the SVGs for the given entries and
     * modify the SVGs according to the type.
     *
     * @param entries - An array of BusinessProcessTaskDataInterface
     * @param type - 'input' or 'output'
     */
    private async setUpEntriesForProcesses(
        entries: Array<BusinessProcessTaskDataInterface>,
        type: 'input' | 'output'
    ): Promise<void> {
        await Promise.all(
            entries.map(async (bpTaskData: BusinessProcessTaskDataInterface, index: number) => {
                let svgName: BpTaskDataType | string;
                switch (+bpTaskData.category) {
                    case 1:
                        // Document
                        svgName = BpTaskDataType.document;
                        break;
                    case 2:
                        // mDocument
                        svgName = BpTaskDataType.mDocument;
                        break;
                    case 3:
                    case 7:
                    case 8:
                        // Table
                        // Linked table
                        // SAP Table
                        svgName = BpTaskDataType.table;
                        break;
                    case 4:
                        // mTable
                        svgName = BpTaskDataType.mTable;
                        break;
                    case 5:
                        // File
                        svgName = BpTaskDataType.file;
                        break;
                    case 6:
                        // mFile
                        svgName = BpTaskDataType.mFile;
                        break;
                    case 9:
                        // FiDocument
                        svgName = this.getSvgNameForFiDocument(bpTaskData);
                        bpTaskData.metadata['credit'] = 'Credit';
                        bpTaskData.metadata['debit'] = 'Debit';
                        break;
                }

                if (bpTaskData.metadata && bpTaskData.metadata['_sourceType']) {
                    svgName = `${svgName}_${bpTaskData.metadata['_sourceType']}`;
                }

                if (!this.service.main.get(svgName)) {
                    const svg = await lastValueFrom(this.fetchSvg(svgName));

                    this.service.main.set(svgName, {
                        svg,
                        type: svgName,
                    });
                }

                await this.modifySVGByType(bpTaskData, svgName, index, type);
            })
        );
    }

    /**
     * Gets the SVG name for a FiDocument based on the number of credits and debits.
     * The SVG name is in the format 'fiDocument_<numOfDebits>_<numOfCredits>'.
     * If the number of credits or debits is 1, it is not included in the SVG name.
     * If the number of credits or debits is 2 or more, the number is included in the SVG name.
     * If the number of credits is 3 or more and the number of debits is 1, the number of credits is set to 3.
     * If the number of debits is 3 or more and the number of credits is 1, the number of debits is set to 3.
     * @param bpTaskData The BusinessProcessTaskDataInterface object
     * @returns The SVG name for the FiDocument
     */
    private getSvgNameForFiDocument(bpTaskData: BusinessProcessTaskDataInterface): string {
        let credits = bpTaskData.metadata['credits']?.length || 1;
        let debits = bpTaskData.metadata['debits']?.length || 1;

        let numOfCredits;
        let numOfDebits;
        if (credits >= 2 && debits >= 2) {
            numOfCredits = 2;
            numOfDebits = 2;
        } else if (credits >= 3 && debits === 1) {
            numOfCredits = 3;
            numOfDebits = 1;
        } else if (credits === 1 && debits >= 3) {
            numOfCredits = 1;
            numOfDebits = 3;
        } else {
            numOfCredits = credits;
            numOfDebits = debits;
        }
        return BpTaskDataType.fiDocument + '_' + numOfDebits + '_' + numOfCredits;
    }

    /**
     * This function sets up the blank entries for processes. It will fetch the SVGs for the given entries
     * and modify the SVGs according to the type.
     *
     * @param type - 'input' or 'output'
     */
    private async setUpBlankEntryForProcess(type: 'input' | 'output'): Promise<void> {
        const svgName = BpTaskDataType.blank;
        if (!this.service.main.get(svgName)) {
            const svg = await lastValueFrom(this.fetchSvg(svgName));

            this.service.main.set(svgName, {
                svg,
                type: svgName,
            });
        }

        await this.modifySVGByType({}, svgName, 0, type);
    }

    /**
     * Sets up the entries for a Decision. It will fetch the SVGs for the given entries
     * and modify the SVGs according to the type.
     *
     * @param entries The array of BusinessProcessTaskDataInterface objects
     */
    async setUpEntriesForDecision(entries: Array<BusinessProcessTaskDataInterface>): Promise<void> {
        await Promise.all(
            entries.map(async (bpTaskData: BusinessProcessTaskDataInterface, index: number) => {
                let svgName = BpTaskDataType.decisionOutput;

                if (!this.service.main.get(svgName)) {
                    const svg = await lastValueFrom(this.fetchSvg(svgName));

                    this.service.main.set(svgName, {
                        svg,
                        type: svgName,
                    });
                }

                await this.modifySVGByType(bpTaskData, svgName, index, 'output');
            })
        );
    }

    /**
     * Modifies the SVG content based on the provided parameters.
     * It replaces placeholders with actual contents and positions the SVG elements accordingly.
     *
     * @param bpTaskData The BusinessProcessTaskDataInterface object containing task data
     * @param svgName The name of the SVG to modify
     * @param index The index of the SVG element
     * @param type The type of the SVG element, can be 'input' or 'output'
     */
    async modifySVGByType(
        bpTaskData: BusinessProcessTaskDataInterface,
        svgName: string,
        index: number,
        type: 'input' | 'output'
    ): Promise<void> {
        let svgContent = this.service.main.get(svgName).svg;
        let entries: any;

        if (bpTaskData.category === 9) {
            entries = { ...properties.fiDocument };
            entries.height = +svgName.split('_')[1] * 25;

            entries.yMultiplier = entries.yMultiplier; //+ +svgName.split('_')[1];

            svgContent = this.replacePlaceholdersWithContentsForFiDocument(
                svgContent,
                entries.content,
                bpTaskData
            );
        } else {
            entries = properties.entries;

            svgContent = this.replacePlaceholdersWithContents(
                svgContent,
                entries.content,
                bpTaskData
            );
        }

        let content = this.svg.toString();
        content += svgContent;

        this.svg.svg(content);

        let x = entries[type].x;
        if (type === 'output') {
            x -= 1;
        }
        let y = entries[type].y + index * entries.yMultiplier;
        if (this.tasks()[0].data.action.category === 'SAP_TASK') {
            y = entries[type].y + index * 13.5;
        }

        this.setPositionAndSizeToSvg(x, y, entries.width, entries.height);

        if (type === 'output') {
            x += 1;
        }

        if (svgName !== 'blank') {
            await this.setUpArrowForEntry(
                type === 'input' ? 'entryStart' : 'entryEnd',
                x,
                75 * index
            );
        }

        if (index > 0) {
            await this.setUpVerticalArrow(type, x, 75 * index);
        }
    }

    /**
     * Sets up the arrow for the specified type and position.
     *
     * @param type - The type of the arrow: 'entryStart' | 'entryEnd' | 'mainStart' | 'mainEnd'
     * @param x - The x-coordinate position
     * @param y - The y-coordinate position
     */
    async setUpArrowForEntry(
        type: 'entryStart' | 'entryEnd' | 'mainStart' | 'mainEnd',
        x: number,
        y: number
    ): Promise<void> {
        if (type === 'entryStart' || type === 'mainStart') {
            x += type === 'entryStart' ? 105 : 217.5;
            y += 282.5;
            this.createArrowStartingPoint(x, y);
            this.createArrowLine(x, y, x + 20, y);
        } else {
            x += type === 'mainEnd' ? 87.5 : 200;
            y += 282.5;
            this.createArrowLine(x, y, x + 20, y);
            this.createArrowTip(x + 20, y, 'horizontal');
        }
    }
    // #endregion

    // #region Dependencies

    private hasDirectArrowDrawn = false;
    private isLastTask = false;

    /**
     * Handles the task dependencies by drawing arrows based on the task's dependency status.
     *
     * This method determines the dependency status of the given task and sets up arrows
     * accordingly. It considers direct, indirect, and ongoing indirect dependencies, and
     * handles special cases such as the first and last tasks in the flow.
     *
     * Arrows are drawn for:
     * - Direct dependencies if the task is the first, last, or has a direct dependency.
     * - Indirect start and end dependencies if they exist.
     * - Passthrough scenarios if ongoing indirect passthrough exists.
     * - Hidden passthrough or direct arrows if there are no ongoing passthroughs or indirects.
     *
     * @param task The BusinessProcessTask for which dependencies are to be handled.
     * @returns Promise that resolves when the task dependencies are handled.
     */
    public async handleTaskDependencies(task: BusinessProcessTask): Promise<void> {
        const hasDirect = this.dependencyService.hasDirectDependent(task); // 'direct'
        const hasIndirectStart = this.dependencyService.hasIndirectDependent(task); // 'indirect-start'
        const hasOngoingIndirectPassthrough =
            this.dependencyService.areThereAnyOngoingIndirectDependencies(task); // 'passthrough'
        const hasIndirectEnd = this.dependencyService.nextTaskHasIndirectDependency(task); // 'indirect-end'

        const taskIndex = this.dependencyService.tasks().findIndex(t => t.id === task.id);
        const isFirstTask = this.isStartPoint;
        this.isLastTask = taskIndex === this.dependencyService.tasks().length - 1;

        // Draw direct arrow if it's the first, last, or has direct dependency
        if (isFirstTask || this.isLastTask || hasDirect) {
            this.hasDirectArrowDrawn = true;
            await this.setUpArrowForDependency(task, 'direct');
        }

        // Draw indirect start arrow if it exists
        if (hasIndirectStart) {
            await this.setUpArrowForDependency(task, 'indirect-start');
        }

        // Draw passthrough arrow if ongoing indirect passthrough exists
        if (hasOngoingIndirectPassthrough) {
            await this.setUpArrowForDependency(task, 'passthrough');
        }

        // Handle passthrough scenarios based on indirect start/end conditions
        if (!hasOngoingIndirectPassthrough && (hasIndirectStart || hasIndirectEnd)) {
            if (hasIndirectStart) {
                await this.setUpArrowForDependency(task, 'passthrough-start');
            } else if (hasIndirectEnd) {
                await this.setUpArrowForDependency(task, 'passthrough-end');
            }
        }

        // Draw indirect end arrow if it exists
        if (hasIndirectEnd) {
            await this.setUpArrowForDependency(task, 'indirect-end');
        }

        // Draw hidden passthrough if no ongoing passthrough or indirects
        if (!hasOngoingIndirectPassthrough && !hasIndirectStart && !hasIndirectEnd) {
            await this.setUpHiddenArrowForDependency(task, 'passthrough');
        }

        // Draw hidden direct arrow if no direct dependencies
        if (!isFirstTask && !this.isLastTask && !hasDirect) {
            await this.setUpHiddenArrowForDependency(task, 'direct');
        }
    }

    /**
     * Sets up the SVG arrow between tasks based on the dependency type.
     *
     * @param task The current task to connect.
     * @param type The type of dependency arrow to draw ('direct', 'indirect-start', 'indirect-end', 'passthrough').
     */
    private async setUpArrowForDependency(
        task: BusinessProcessTask,
        type:
            | 'direct'
            | 'indirect-start'
            | 'indirect-end'
            | 'passthrough'
            | 'passthrough-start'
            | 'passthrough-end'
    ): Promise<void> {
        // Drawing logic based on dependency type
        switch (type) {
            case 'direct':
                // Draw direct arrow from task to its dependent
                this.createArrowToNextTask(task);
                break;
            case 'indirect-start':
                // Draw indirect start arrow
                await this.drawIndirectStartOrEnd(task, 'start');
                break;
            case 'indirect-end':
                // Draw indirect end arrow
                await this.drawIndirectStartOrEnd(task, 'end');
                break;
            case 'passthrough':
                // Draw passthrough arrow
                this.setUpVerticalArrowForDependency(task, 'passthrough');
                break;
            case 'passthrough-start':
                // Draw passthrough arrow
                this.setUpVerticalArrowForDependency(task, 'start');
                break;
            case 'passthrough-end':
                // Draw passthrough arrow
                this.setUpVerticalArrowForDependency(task, 'end');
                break;
        }
        // Async drawing operations or SVG updates can be handled here.
    }

    /**
     * Sets up a hidden version of the SVG arrow between tasks when the dependency is not present.
     *
     * @param task The current task to set the hidden arrow for.
     * @param type The type of dependency arrow to hide ('direct', 'indirect-start', 'indirect-end', 'passthrough').
     */
    private async setUpHiddenArrowForDependency(
        task: BusinessProcessTask,
        type: 'direct' | 'passthrough'
    ): Promise<void> {
        switch (type) {
            case 'direct':
                // Draw direct arrow from task to its dependent
                this.createArrowToNextTask(task, true);
                break;
            case 'passthrough':
                // Draw passthrough arrow
                this.setUpVerticalArrowForDependency(task, 'passthrough', true);
                break;
        }
    }

    /**
     * Returns the y-coordinate position for the dependency arrow.
     *
     * If the current task is a startpoint, the y-coordinate is the starting y position for the arrow.
     * If the current task is an endpoint, the y-coordinate is 0.
     * If the current task is a process, the y-coordinate is the starting y position for the arrow plus the length of the arrow.
     * If the current task is a decision, the y-coordinate is the starting y position for the arrow plus half of the length of the arrow.
     *
     * @returns The y-coordinate position for the dependency arrow.
     */
    private async getYForDependency(): Promise<number> {
        let maxLength = 0;

        if (this.isStartPoint || this.isEndPoint) {
            await this.createStartOrEndPoint();
        } else {
            const { input, output } = this.tasks()[0].data;
            maxLength = Math.max(input.length, output.length);
        }

        const base = this.tasks()[0].category === 'decission' ? 50 : 75;
        const height = this.svg.bbox().height;

        // Determine category
        let category = 'process';
        if (this.isStartPoint) {
            category = 'startpoint';
        } else if (this.isEndPoint) {
            return 0; // Early return if endpoint
        } else if (this.tasks()[0].category !== BusinessProcessCategory.IntegrationTask) {
            category = this.tasks()[0].category;
        }

        // Determine starting Y position based on category
        const startY = category === 'process' ? 325 : category === 'startpoint' ? 21.5 : 310;

        // Determine arrow length
        const arrowLength = this.isStartPoint
            ? 22.5
            : (maxLength > 1 ? height - base : height / 2) - 10;

        return startY + arrowLength;
    }

    indirectDependencyX = -5;

    /**
     * Set up the arrow for the dependency of a task, given the type of setup (start or end).
     *
     * For the start case, create the starting point and horizontal arrow line.
     * For the end case, get the dynamic Y position, draw the arrow tip, and create the horizontal and vertical lines.
     * @param type The type of setup (start or end).
     */
    async drawIndirectStartOrEnd(task: BusinessProcessTask, type: 'start' | 'end'): Promise<void> {
        let x = this.indirectDependencyX;
        let y = 282.5;

        if (type === 'start') {
            // Create starting point and horizontal arrow
            if (task.category === 'decission') {
                this.createArrowStartingPoint(x + 150, y);
                this.createDependencyArrowLine(x, y, x + 150, y);
            } else {
                const startX = 200;
                let startY = 325;
                const base = 75;
                const height = this.svg.bbox().height;
                const maxLength = Math.max(task.data.input.length, task.data.output.length);
                let arrowLength =
                    (maxLength > 1 ? height - base : 60) -
                    15 -
                    (task.data.action.category === 'SAP_TASK' ? 20 : 0); // 25;

                if (!this.hasDirectArrowDrawn) {
                    // if (task.data.action.category === 'SAP_TASK') {
                    //     arrowLength += 40;
                    // }
                    this.createArrowStartingPoint(startX, startY);
                    this.createDependencyArrowLine(startX, startY, startX, startY + arrowLength);
                }
                this.createDependencyArrowLine(
                    x,
                    startY + arrowLength,
                    startX,
                    startY + arrowLength
                );
            }
        } else {
            let y: number;
            if (this.y2) {
                y = this.y2;
            } else {
                // Get the dynamic Y position for the 'end' case
                y = await this.getYForDependency();
                if (task.data.action.category === 'SAP_TASK') {
                    y -= 25;
                }
            }
            // Adjust position for the horizontal line

            this.createDependencyArrowLine(x, y, x + 205, y); // Horizontal

            // Create vertical line and tip
            if (!this.hasDirectArrowDrawn) {
                this.createDependencyArrowLine(x + 205, y, x + 205, y + 5); // Vertical
                // Draw arrow tip
                this.createArrowTip(x + 205, y + 5, 'vertical');
            }
        }
    }

    private y2: number;

    /**
     * Set up the vertical arrow for the specified type, position, and visibility.
     *
     * Draws arrows depending on the `type` ('start', 'passthrough', 'end') and the category of the task.
     * If `isHidden` is true, the arrow is not drawn.
     *
     * @param task The task for which to set the arrow.
     * @param type The type of the arrow: 'start' | 'passthrough' | 'end'.
     * @param isHidden Whether the arrow should be hidden.
     */
    async setUpVerticalArrowForDependency(
        task: BusinessProcessTask,
        type: 'start' | 'passthrough' | 'end',
        isHidden?: boolean
    ): Promise<void> {
        const x1 = this.indirectDependencyX;
        const {
            category,
            data: { action, input, output },
        } = task;
        let y1 = this.getInitialY1(category, type, action.category);

        // Handle start or end point early if necessary
        if (this.isStartPoint || this.isEndPoint) {
            await this.createStartOrEndPoint();
            if (this.isEndPoint) return; // Exit early for end points
        }

        const maxLength = Math.max(input.length, output.length);
        let y2 = await this.calculateY2(type, category, maxLength);

        // Adjust y1 for non-'decission' start type
        if (type === 'start' && category !== 'decission') {
            y1 = y2 - 5;
        }

        if (task.data.action.category === 'SAP_TASK' && type === 'start') {
            y1 -= 10;
            y2 -= 10;
        } else if (
            task.data.action.category === 'SAP_TASK' &&
            type === 'end' &&
            !(task.data.input?.length > 1 || task.data.output?.length > 1)
        ) {
            y2 += 10;
        }

        // Further adjustments based on specific conditions
        const adjustedY2 = this.adjustY2ForSapTask(type, action.category, y2);

        // Create the actual dependency arrow line with the calculated positions
        this.createDependencyArrowLine(x1, y1, x1, adjustedY2, isHidden, 2);
    }

    /**
     * Get initial Y1 position based on task category and type.
     */
    private getInitialY1(category: string, type: string, actionCategory: string): number {
        if (type === 'start' && category === 'decission') {
            return 282.5;
        }

        switch (category) {
            case 'decission':
                return 255;
            case 'process':
                return actionCategory === 'SAP_TASK' ? 225 : 245;
            default:
                return actionCategory === 'SAP_TASK' ? 225 : 245; // Default Y1 value
        }
    }

    /**
     * Calculate Y2 based on the task type, category, and maximum length of input/output.
     */
    private async calculateY2(type: string, category: string, maxLength: number): Promise<number> {
        let y2: number;

        if (maxLength > 1) {
            y2 = await this.getYForDependency();
            if (type === 'end') {
                y2 -= 10;
            }
        } else {
            const startY = category === 'process' ? 325 : 310;
            const arrowLength = 50;
            y2 = startY + arrowLength + 10;
            if (type === 'end') {
                y2 -= 15;
            }
        }

        return y2 + 5; // General Y2 adjustment
    }

    /**
     * Adjust Y2 specifically for SAP tasks based on the arrow type.
     */
    private adjustY2ForSapTask(type: string, actionCategory: string, y2: number): number {
        if (actionCategory === 'SAP_TASK') {
            return y2 - 20;
        }
        return y2;
    }

    /**
     * Creates an arrow pointing to the next task in the process.
     * @param maxLength The maximum length of the arrow.
     * @param isHidden Whether the arrow should be hidden.
     * @returns A promise that resolves when the arrow has been created.
     */
    async createArrowToNextTask(task: BusinessProcessTask, isHidden?: boolean): Promise<void> {
        const isStart = this.isStartPoint;
        const isEnd = this.isEndPoint;

        let maxLength: number = 0;
        if (task) {
            maxLength = Math.max(task.data.input.length, task.data.output.length);
        }

        // Handle the start point case
        if (isStart) {
            this.drawArrow(207, 21.5, 22.5, isHidden);
            return;
        }

        // If it's an end point, we return early without drawing anything
        if (isEnd) {
            return;
        }

        // Proceed with normal flow for tasks
        const base = task.category === 'decission' ? 50 : 75;
        const height = this.svg.bbox().height;

        const category =
            task.category === BusinessProcessCategory.IntegrationTask ? 'process' : task.category;

        // Define arrow parameters based on the category
        const startX = category === 'process' ? 202.5 : 202.5;
        const startY = category === 'process' ? 325 : 310;
        let arrowLength: number;
        if (task.category === 'process') {
            arrowLength = (maxLength > 1 ? height - base : 60) - 10; // 25;
        } else {
            arrowLength = maxLength > 1 ? height - base - 10 : 50; // 25;
        }

        if (isHidden && task.data.action.category === 'SAP_TASK') {
            arrowLength -= 20;
        }

        // Draw the arrow components
        if (task.category === 'decission' || this.isLastTask) {
            this.drawArrow(startX, startY, arrowLength + 7, isHidden);
        } else if (task.data.action.category === 'SAP_TASK') {
            let y2 = await this.getYForDependency();
            this.drawArrow(startX, startY, y2 - startY + 7, isHidden);
        } else {
            const maxLength = Math.max(task.data.input.length, task.data.output.length);
            let y2 = await this.calculateY2('', task.category, maxLength);
            this.drawArrow(startX, startY, y2 - startY + 7, isHidden);
        }
    }

    /**
     * Sets the position and size of the last child of the SVG element to the given
     * x, y, width and height.
     *
     * @param x The x-coordinate of the top-left corner of the element.
     * @param y The y-coordinate of the top-left corner of the element.
     * @param width The width of the element.
     * @param height The height of the element.
     */
    private setPositionAndSizeToSvg(x: number, y: number, width: number, height: number): void {
        const length = this.svg.children().length;
        this.svg.children()[length - 1].width(`${width}%`);
        height = this.isStartPoint || this.isEndPoint ? 5 : 150;
        this.svg.children()[length - 1].height(`${height}mm`);
        this.svg.children()[length - 1].move(`${x}%`, `${y}%`);
    }

    // #endregion

    // #region Arrows

    /**
     * Set up the vertical arrow for the specified type and position.
     *
     * @param type The type of the arrow: 'input' or 'output'
     * @param x The x-coordinate position
     * @param y The y-coordinate position
     */
    async setUpVerticalArrow(type: 'input' | 'output', x: number, y: number): Promise<void> {
        const x1 = type === 'input' ? 124.5 : 275.5;
        const y1 = y + 208;
        const x2 = x1;
        const y2 = y1 + 75;
        this.createArrowLine(x1, y1, x2, y2);
    }

    /**
     * Draws an arrow starting from the given x and y coordinates, with the given length,
     * and optionally hidden.
     *
     * @param startX The x-coordinate of the starting point of the arrow.
     * @param startY The y-coordinate of the starting point of the arrow.
     * @param arrowLength The length of the arrow.
     * @param isHidden Optional. Whether the arrow should be hidden. Defaults to false.
     */
    private drawArrow(
        startX: number,
        startY: number,
        arrowLength: number,
        isHidden?: boolean
    ): void {
        this.createArrowStartingPoint(startX, startY, isHidden);
        this.createDependencyArrowLine(startX, startY, startX, startY + arrowLength, isHidden);
        this.createArrowTip(startX, startY + arrowLength, 'vertical', isHidden);
    }

    /**
     * Creates the starting point of an arrow at the given x and y coordinates,
     * optionally hidden.
     *
     * @param x The x-coordinate of the starting point of the arrow.
     * @param y The y-coordinate of the starting point of the arrow.
     * @param isHidden Optional. Whether the starting point should be hidden. Defaults to false.
     */
    private createArrowStartingPoint(x: number, y: number, isHidden?: boolean): void {
        const size = 6;
        const circle = this.svg
            .circle(size)
            .addClass(isHidden ? '' : 'arrow')
            .fill(isHidden ? 'transparent' : 'black');
        circle.center(x, y);
    }

    /**
     * Creates a line representing an arrow between two points on the SVG canvas.
     *
     * @param x1 The x-coordinate of the starting point of the line.
     * @param y1 The y-coordinate of the starting point of the line.
     * @param x2 The x-coordinate of the ending point of the line.
     * @param y2 The y-coordinate of the ending point of the line.
     * @param isHidden Optional. Whether the line should be hidden. Defaults to false.
     * @param width Optional. The width of the line. Defaults to 1 if not specified.
     */
    private createArrowLine(
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        isHidden?: boolean,
        width?: number
    ): void {
        const line = this.svg
            .line(x1, y1, x2, y2)
            .stroke({
                width: width ?? 1,
                color: isHidden ? 'transparent' : 'black',
            })
            .addClass(isHidden ? '' : 'line');
    }

    /**
     * Creates a dashed line representing a dependency arrow between two points on the SVG canvas.
     *
     * @param x1 The x-coordinate of the starting point of the line.
     * @param y1 The y-coordinate of the starting point of the line.
     * @param x2 The x-coordinate of the ending point of the line.
     * @param y2 The y-coordinate of the ending point of the line.
     * @param isHidden Optional. Whether the line should be hidden. Defaults to false.
     * @param width Optional. The width of the line. Defaults to 1 if not specified.
     */
    private createDependencyArrowLine(
        x1: number,
        y1: number,
        x2: number,
        y2: number,
        isHidden?: boolean,
        width?: number
    ): void {
        const line = this.svg
            .line(x1, y1, x2, y2)
            .stroke({
                width: width ?? 1,
                color: isHidden ? 'transparent' : 'black',
                dasharray: '5, 2',
            })
            .addClass(isHidden ? '' : 'line');
    }

    /**
     * Creates a small triangle representing an arrowhead on the SVG canvas.
     *
     * @param x The x-coordinate of the arrowhead.
     * @param y The y-coordinate of the arrowhead.
     * @param orientation Whether the arrowhead should be oriented horizontally or vertically.
     * @param isHidden Optional. Whether the arrowhead should be hidden. Defaults to false.
     */
    private createArrowTip(
        x: number,
        y: number,
        orientation: 'horizontal' | 'vertical',
        isHidden?: boolean
    ): void {
        const size = 3;
        let point1: string;
        let point2: string;
        let point3: string;
        if (orientation === 'vertical') {
            point1 = `-${size},0`;
            point2 = `${size},0`;
            point3 = `0,${size * 2}`;
            x -= size;
        } else {
            point1 = `0,${size}`;
            point2 = `0,-${size}`;
            point3 = `${size * 2},0`;
            x -= size;
            y -= size;
        }
        const arrowhead = this.svg
            .polygon(`${point1} ${point2} ${point3}`)
            .fill(isHidden ? 'transparent' : 'black')
            .addClass(isHidden ? '' : 'arrow')
            .move(x, y);
    }

    // #endregion
}
