import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';
import { BusinessProcess, Process } from 'app/api';
import { VerifyService } from 'app/modules/verify/verify.service';
import { ViewService } from 'app/modules/view/view.service';
import { NewMDOService } from 'app/services/new_mdo.service';
import { PrintViewComponent } from '../print-view.component';

@Component({
    selector: 'print-view',
    templateUrl: './bp-print-view.component.html',
    styleUrls: ['../print-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpPrintViewComponent extends PrintViewComponent implements OnInit {
    /**
     * The BusinessProcess that contains the tasks
     */
    @Input() businessProcess: BusinessProcess;
    /**
     * The process that contains the details and the description
     */
    @Input() process?: Process;
    /**
     * The service that is needed for FlowchartContainerComponent. Either ViewService or VerifyService.
     */
    @Input() service: ViewService | VerifyService;

    @Input() image: any;

    @Input() images: any[];

    @Input() taskDescription: 'instructions' | 'description' = 'description';

    /**
     * The signal used to execute actions
     */
    executeActionSignal = signal(null);

    constructor(private _newMdoService: NewMDOService) {
        super();
    }
    ngOnInit(): void {
        const owner = this._newMdoService.usersForSelect.find(
            owner => owner.value === this.process.ownerid
        );
        this.process.owner = owner.value;
    }

    static override print(): void {
        const content = document.querySelector('.print-section');
        this.openAlert();

        this.printContextInNewWindow('Print Business Process');
    }
}
