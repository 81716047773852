import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    OnDestroy,
    OnInit,
    Signal,
    ViewEncapsulation,
    WritableSignal,
    computed,
    effect,
    forwardRef,
    input,
    signal,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseScrollResetDirective } from '@fuse/directives/scroll-reset';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { ResizableModule, ResizeEvent } from 'angular-resizable-element';
import { merge } from 'lodash';
import { FlowchartModule } from '../../../../../projects/flowchart/src/public-api';
import {
    ContentLayoutHeaderComponent,
    ContentLayoutHeaderConfig,
} from '../content-layout-header/content-layout-header.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';

export interface ContentLayoutWrapperConfig {
    rightSection?: {
        header?: ContentLayoutHeaderConfig;
        useLeftContentHeader?: boolean;
    };
    leftSection?: {
        header?: {
            showToggleButton?: boolean;
        };
        content?: {
            spacing?: {
                x?: boolean;
                y?: boolean;
            };
        };
    };
    ultimateSideBar?: {
        header?: {
            showToggleButton?: boolean;
        };
        isOpened?: boolean;
    };
}

@Component({
    selector: 'content-layout-wrapper',
    templateUrl: './content-layout-wrapper.component.html',
    styleUrls: ['./content-layout-wrapper.component.scss'],
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [
        MatSidenavModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        FuseScrollResetDirective,
        ResizableModule,
        NgTemplateOutlet,
        NgStyle,
        NgClass,
        TranslocoModule,
        forwardRef(() => ContentLayoutHeaderComponent),
        forwardRef(() => FlowchartModule),
    ],
})
export class ContentLayoutWrapperComponent
    implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked
{
    /**
     * References to the parent mat drawer
     */
    parentMatDrawer = viewChild.required<MatDrawer>('parentMatDrawer');
    parentMatDrawerEleRef = viewChild.required('parentMatDrawer', {
        read: ElementRef,
    });

    /**
     * Reference to the parent mat drawer content
     */
    parentMatDrawerContentEleRef = viewChild.required('parentMatDrawerContent', {
        read: ElementRef,
    });

    /**
     * References to the child mat drawer
     */
    childMatDrawer = viewChild.required<MatDrawer>('childMatDrawer');
    childMatDrawerEleRef = viewChild.required('childMatDrawer', {
        read: ElementRef,
    });

    /**
     * Reference to the child mat drawer content
     */
    childMatDrawerContentEleRef = viewChild.required('childMatDrawerContent', {
        read: ElementRef,
    });

    /**
     * Default configuration of the layout of this component
     */
    private _defaultConfig = {
        leftSection: {
            content: {
                spacing: {
                    x: true,
                    y: true,
                },
            },
        },
        rightSection: {},
    };

    /**
     * Configuration for the layout of this component
     */
    config = input<ContentLayoutWrapperConfig, ContentLayoutWrapperConfig>(this._defaultConfig, {
        transform: value => merge({}, this._defaultConfig, value),
    });

    autoLeftContentFullscreen = input<boolean>(false);

    /**
     * Initial width of the child drawer in '%' decimal
     */
    childDrawerInitialWidth: number = 0.5;
    /**
     * Minimum width of the child drawer in 'px'
     */
    childDrawerMinWidth: number = 200;
    /**
     * Maximum width of the child drawer in '%' decimal
     */
    childDrawerMaxWidth: number = 0.5;

    /**
     * Initial width of the parent drawer in '%' decimal
     */
    parentDrawerInitialWidth: number = 0.25;
    /**
     * Minimum width of the parent drawer in 'px'
     */
    parentDrawerMinWidth: number = 200;
    /**
     * Maximum width of the parent drawer in '%' decimal
     */
    parentDrawerMaxWidth: number = 0.4;

    /**
     * Child drawer mode
     */
    childDrawerMode: WritableSignal<'over' | 'side'> = signal('side');

    /**
     * Parent drawer mode
     */
    parentDrawerMode: WritableSignal<'over' | 'side'> = signal('over');

    /**
     * Is child drawer open
     */
    childDrawerOpened: Signal<boolean>;

    /**
     * Is parent drawer open
     */
    parentDrawerOpened: Signal<boolean>;

    /**
     * Is child drawer in full screen mode
     */
    isChildDrawerFullscreen: WritableSignal<boolean> = signal(false);
    isChildDrawerContentFullScreen: WritableSignal<boolean> = signal(false);

    parentStyle: object = {};
    childStyle: object = {};

    resizeInProgress = false;
    drawerClosinInProgress = false;

    isInit: boolean = true;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _destroyRef: DestroyRef,
        private _elementRef: ElementRef
    ) {
        this.setUpChildMatDrawer();
        this.setUpParentMatDrawer();

        effect(
            () => {
                const fullscreen = this.autoLeftContentFullscreen();
                if (fullscreen && this.isInit) {
                    this.toggleChildMatDrawerFullScreen();
                    this.isInit = false;
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }
    ngAfterViewInit(): void {
        const parentDrawerMode = localStorage.getItem('parentDrawerMode');
        if (parentDrawerMode) {
            this.parentDrawerMode.set(parentDrawerMode as any);
        }
    }

    ngOnInit(): void {
        // Subscribe to media query change
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(({ matchingAliases }) => {
                // Set the drawerMode
                if (matchingAliases.includes('md')) {
                    this.childDrawerMode.set('side');
                } else {
                    this.childDrawerMode.set('over');
                }
                this.parentDrawerMode.set(
                    this.config().ultimateSideBar?.isOpened ? 'side' : 'over'
                );
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnDestroy(): void {}

    ngAfterViewChecked(): void {
        this.setMatDrawerContentSize();
    }

    setUpChildMatDrawer() {
        // update child drawer opened state
        this.childDrawerOpened = computed(
            () => this.childDrawerMode() === 'side' || this.isChildDrawerFullscreen()
        );

        effect(() => {
            if (!this.childMatDrawer()) return;
            // when child mat drawer finishes closing
            this.childMatDrawer()
                ._closedStream.pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(() => {
                    this.drawerClosinInProgress = false;
                });

            // when child mat drawer starts closing
            this.childMatDrawer()
                .closedStart.pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(() => {
                    this.drawerClosinInProgress = true;
                    let element = this.childMatDrawerContentEleRef();
                    if (!element) return;
                    // if not done, the content would wait for the drawer to close
                    // in the meantime, there would be white space visible in the place of drawer
                    element.nativeElement.style.marginLeft = '0px';
                });
        });

        effect(() => {
            if (!this.childMatDrawerEleRef()) return;

            this.childMatDrawerEleRef().nativeElement.style.setProperty(
                'max-width',
                `100%`,
                'important'
            );

            let width = this.childMatDrawer().mode === 'over' ? 0.5 : this.childDrawerInitialWidth;
            this.childMatDrawerEleRef().nativeElement.style.setProperty('width', `${width * 100}%`);
        });
    }

    setUpParentMatDrawer() {
        // update parent drawer opened state
        this.parentDrawerOpened = computed(() => this.parentDrawerMode() === 'side');

        effect(() => {
            if (!this.parentMatDrawer()) return;
            // when child mat drawer finishes closing
            this.parentMatDrawer()
                ._closedStream.pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(() => {
                    this.drawerClosinInProgress = false;
                });

            // when child mat drawer starts closing
            this.parentMatDrawer()
                .closedStart.pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(() => {
                    this.drawerClosinInProgress = true;
                    let element = this.parentMatDrawerContentEleRef();
                    if (!element) return;
                    // if not done, the content would wait for the drawer to close
                    // in the meantime, there would be white space visible in the place of drawer
                    element.nativeElement.style.marginLeft = '0px';
                });
        });

        effect(() => {
            if (!this.parentMatDrawerEleRef()) return;

            this.parentMatDrawerEleRef().nativeElement.style.setProperty(
                'max-width',
                `100%`,
                'important'
            );

            let width =
                this.parentMatDrawer().mode === 'over' ? 0.33 : this.parentDrawerInitialWidth;
            this.parentMatDrawerEleRef().nativeElement.style.setProperty(
                'width',
                `${width * 100}%`
            );
        });
    }

    /**
     * It determines whether the resizing is possible when the user is trying to resize
     * It is called on every resize event being fired when the user is trying to resize
     *
     * @returns fuction that determines whether the resizing is possible
     */
    validate(entity: 'parent' | 'child'): Function {
        return (event: ResizeEvent): boolean => {
            const minWidth =
                entity === 'parent' ? this.parentDrawerMinWidth : this.childDrawerMinWidth;
            const maxWidth =
                entity === 'parent' ? this.parentDrawerMaxWidth : this.childDrawerMaxWidth;
            if (
                event.rectangle.width &&
                (event.rectangle.width < minWidth ||
                    event.rectangle.width > this._elementRef.nativeElement.clientWidth * maxWidth)
            ) {
                return false;
            }

            // needed to continuously adjust the content size as the size of the drawer changes
            let element =
                entity === 'parent'
                    ? this.parentMatDrawerContentEleRef()
                    : this.childMatDrawerContentEleRef();
            if (!element) return false;
            element.nativeElement.style.marginLeft = `${event.rectangle.width}px`;
            return true;
        };
    }

    /**
     * It is called when resizing starts
     *
     * @param event - resize event
     */
    onResizeStart(event: ResizeEvent) {
        this.resizeInProgress = true;
    }

    /**
     * It is called when resizing ends
     *
     * @param event - resize event
     */
    onResizeEnd(event: ResizeEvent, entity: 'parent' | 'child'): void {
        this.resizeInProgress = false;

        // set the width of the drawer after resizing ends
        if (entity === 'parent')
            this.parentStyle = {
                width: `${event.rectangle.width}px`,
            };
        else {
            this.childStyle = {
                width: `${event.rectangle.width}px`,
            };
        }

        // if validate fails, then make sure the content is back to its proper size leaving no white space/overlap
        setTimeout(() => {
            let element =
                entity === 'parent'
                    ? this.parentMatDrawerContentEleRef()
                    : this.childMatDrawerContentEleRef();
            element.nativeElement.style.marginLeft = `${event.rectangle.width}px`;
        });
    }

    /**
     * Updates the mat drawer content (right side) width
     *
     * @returns
     */
    setMatDrawerContentSize(): void {
        if (this.resizeInProgress || this.drawerClosinInProgress) return;

        // if the drawer is over the content, then there is no resize functionality
        if (this.childDrawerMode() !== 'over') {
            let element = this.childMatDrawerContentEleRef();
            if (element)
                element.nativeElement.style.marginLeft = `${this.childMatDrawer()._getWidth()}px`;
        }
        if (this.parentDrawerMode() !== 'over') {
            let element = this.parentMatDrawerContentEleRef();
            if (element)
                element.nativeElement.style.marginLeft = `${this.parentMatDrawer()._getWidth()}px`;
        }
    }

    /**
     * Toggle the state of the child mat drawer between 'open' and 'close'
     */
    toggleChildMatDrawer() {
        this.childMatDrawer()
            .toggle()
            .then(value => {
                if (value === 'open') this.isChildDrawerContentFullScreen.update(value => false);
                else this.isChildDrawerContentFullScreen.update(value => true);
            })
            .finally(() => {
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * Toggle child mat drawer full screen mode
     */
    toggleChildMatDrawerFullScreen() {
        this.isChildDrawerFullscreen.update(value => !value);
        if (this.isChildDrawerFullscreen()) {
            this.childDrawerMode.set('over');
        } else {
            this.childDrawerMode.set('side');
        }

        this._changeDetectorRef.markForCheck();
    }

    /**
     * Toggle the state of the parent mat drawer between 'over' and 'side'
     */
    toggleParentDrawerMode() {
        if (this.parentDrawerMode() === 'side') this.parentDrawerMode.set('over');
        else this.parentDrawerMode.set('side');

        localStorage.setItem('parentDrawerMode', this.parentDrawerMode());
    }
}
